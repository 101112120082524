import { api } from "./axios";

export const fetchBranchTypes = async () => {
    try {
      const response = await api.get('branchs-types', {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      
      if (response.data.status === 200) {
        return response.data.data; 
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      throw new Error('Failed to fetch branch types');
    }
  }