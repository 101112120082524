import React from 'react';
import CategoryCarousel from '../components/CategoryCarrousel'; // Ajuste o caminho conforme necessário
import RedirectIfLoggedIn from '../components/RedirectToLogin';


const HomePage: React.FC = () => {
  return (
    <div className="p-4">
      <RedirectIfLoggedIn /> 
      <CategoryCarousel />
    </div>

    
    
  );
};

export default HomePage;
