import axios from 'axios';
import { User } from '../interfaces/user_interface';
import { api } from './axios';



export const registerUser = async (
    name: string,
    cpf: string,
    phone: string,
    role: string,
    email: string,
    password: string,
    received_wpp: boolean,
    received_sms: boolean,
    received_email: boolean
) => {
    const requestData = {
        name,
        cpf,
        phone,
        role,
        email,
        password,
        received_wpp,
        received_sms,
        received_email
    };

    try {
        const response = await api.post('register', requestData);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during registration:', error.response.data);
        } else {
            console.error('Error during registration:', error);
        }
        throw error;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await api.get('users', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during fetch users:', error.response.data);
        } else {
            console.error('Error during fetch users:', error);
        }
        throw error;
    }
};

export const fetchUserDetail = async (userId: string) => {
    try {
        const response = await api.get(`users/${userId}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during fetch user:', error.response.data);
        } else {
            console.error('Error during fetch user:', error);
        }
        throw error;
    }
};

export const updateUserByAdmin = async (user: User, role: string) => {
    const requestData = {
        email: user.email,
        phone: user.phone,
        cpf: user.cpf,
        name: user.name,
        role
    };

    try {
        const response = await api.put(`users/${user.id}`, requestData, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during update user:', error.response.data);
        } else {
            console.error('Error during update user:', error);
        }
        throw error;
    }
};

export const deleteUserByAdmin = async (userId: string) => {
    try {
        const response = await api.delete(`users/${userId}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during delete user:', error.response.data);
        } else {
            console.error('Error during delete user:', error);
        }
        throw error;
    }
};
export const createUserByAdmin = async (
    name: string,
    cpf: string,
    phone: string,
    role: string,
    email: string,
    password: string,
    received_wpp: boolean,
    received_sms: boolean,
    received_email: boolean
  ) => {
    const requestData = {
      name: name,
      cpf: cpf,
      phone: phone,
      role: role,
      email: email,
      password: password,
      reiceved_wpp: received_wpp,
      reiceved_sms: received_sms,
      reiceved_mail: received_email
    };
  
    try{
      const response = await api.post('users', requestData, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
  
      return response.data;
      
    }catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error during create user:', error.response.data);
      } else {
        console.error('Error during create user :', error);
      }
      throw error;
    }
  }