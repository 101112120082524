import axios from 'axios';
import { api } from './axios';



export const fetchDataAdminDash = async () => {
    try {
        const response = await api.get('admin', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during fetch admin dashboard data:', error.response.data);
        } else {
            console.error('Error during fetch admin dashboard data:', error);
        }
        throw error;
    }
};
