import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectIfLoggedIn: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = sessionStorage.getItem('authToken');
    const role = sessionStorage.getItem('role');

    if (authToken && role) {
      switch (role) {
        case 'company':
          navigate('/companies');
          break;
        case 'user':
          navigate('/home');
          break;
        case 'admin':
          navigate('/admin');
          break;
        default:
          navigate('/login'); 
          break;
      }
    }
  }, [navigate]);

  return null;
};

export default RedirectIfLoggedIn;
