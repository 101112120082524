import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar';
import { FaSearch, FaEdit, FaEye, FaTrash, FaUser } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteUserByAdmin, fetchUsers } from '../../../services/userService';


const AdminUsersPage: React.FC = () => {
  const isLoggedIn = !!sessionStorage.getItem('authToken');
  const role = sessionStorage.getItem('role');
  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [cpfFilter, setCpfFilter] = useState('');
  const [users, setUsers] = useState<any[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchUsers();
        setUsers(data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    if (isLoggedIn && role === 'admin') {
      fetchData();
    }
  }, [isLoggedIn, role]);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = users.filter(user => {
        const matchesSearch = user.name.toLowerCase().includes(search.toLowerCase());
        const matchesRole = roleFilter ? user.role === roleFilter : true;
        const matchesCpf = cpfFilter ? user.cpf.includes(cpfFilter) : true;
        return matchesSearch && matchesRole && matchesCpf;
      });

      setFilteredUsers(filtered);
      setPageCount(Math.ceil(filtered.length / 15)); // Define o número de páginas com base no número filtrado de usuários
    };

    applyFilters();
  }, [users, search, roleFilter, cpfFilter]);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
  };

  const handleEdit = (userId: number) => {
    navigate(`/admin/users/edit/${userId}`);
  };

  const handleView = (userId: number) => {
    navigate(`/admin/users/${userId}`);
  };

  const handleDelete = (userId: number) => {
    setUserIdToDelete(userId);
    setModalVisible(true);
  };

  const handleCreate = () => {
    navigate('/admin/users/create');
  }

  const confirmDelete = async () => {
    if (userIdToDelete !== null) {
      const response = await deleteUserByAdmin(userIdToDelete.toString());
      toast.success(response.message);
      setModalVisible(false);

      if (response.status === 200) {
        setUsers(users.filter(user => user.id !== userIdToDelete));
        setFilteredUsers(filteredUsers.filter(user => user.id !== userIdToDelete));
      }
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />

      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        {/* Navbar */}
        <nav className="bg-gray-100 px-6 py-4 flex justify-between items-center w-100">
          <div className="relative flex-grow">
            <input
              id="search"
              type="text"
              placeholder="Busque aqui..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
              required
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </nav>

        {/* Filtros */}
        <div className="p-4 bg-gray-100">
          <div className="flex flex-wrap gap-4">
            <div className="flex-1">
              <label htmlFor="roleFilter" className="block text-sm font-medium text-gray-700">Filtrar por Role</label>
              <select
                id="roleFilter"
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Todos</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="company">Company</option>
              </select>
            </div>
            <div className="flex-1">
              <label htmlFor="cpfFilter" className="block text-sm font-medium text-gray-700">Buscar por CPF</label>
              <input
                id="cpfFilter"
                type="text"
                value={cpfFilter}
                onChange={(e) => setCpfFilter(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
              />
            </div>
          </div>
        </div>
        {/* Main Content */}
        <div className="flex flex-col flex-grow p-6 bg-gray-100">
        <div className="flex justify-start mb-4">
          <button type="submit" onClick={handleCreate} className='bg-mosaico text-white px-4 py-2 rounded-md flex items-center space-x-2'>
            <FaUser size={16} />
            <span>Novo Usuário</span> 
          </button>
        </div>

          <h2 className="text-2xl font-texta-regular text-gray-700 mb-6">Usuarios </h2>

          
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefone</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredUsers.slice(currentPage * 15, (currentPage + 1) * 15).map(user => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.phone}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button onClick={() => handleView(user.id)} className="text-blue-500 hover:text-blue-700 mr-2">
                        <FaEye />
                      </button>
                      <button onClick={() => handleEdit(user.id)} className="text-yellow-500 hover:text-yellow-700 mr-2">
                        <FaEdit />
                      </button>
                      <button onClick={() => handleDelete(user.id)} className="text-red-500 hover:text-red-700">
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-6 flex justify-center">
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              breakLabel={'...'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'flex space-x-1'}
              pageClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              pageLinkClassName={'text-sm'}
              previousClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              previousLinkClassName={'text-sm '}
              nextClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              nextLinkClassName={'text-sm '}
              breakClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded'}
              breakLinkClassName={'text-sm'}
              activeClassName={'bg-mosaico text-white'}
            />
          </div>
        </div>
      </div>

      {/* Modal de Confirmação */}
      {modalVisible && (
        <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Confirmar Exclusão</h2>
            <p className="mb-4">Você tem certeza que deseja excluir este usuário? </p>
            <small className="mb-4">Todos os dados, como empresas, ofertas, cupons, tokens e demais informações vinculadas a este usuário, também serão excluídos! </small>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setModalVisible(false)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                Cancelar
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Excluir
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUsersPage;
