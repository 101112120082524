import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import { FaBookOpen, FaUpload, FaEye } from 'react-icons/fa';
import { createCategory } from '../../../services/categoryService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const AdminCategoriesCreatePage: React.FC = () => {
    const [name, setName] = useState('');
    const [caption, setCaption] = useState<File | null>(null);
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            if (!caption) {
                toast.error('Please upload an image for the category.');
                setLoading(false);
                return;
            }

            const response = await createCategory(name, caption, visible);
            toast.success(response.message);
            navigate('/admin/categories');
        } catch (error) {
            toast.error('Failed to create category.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100 font-texta-regular">
            <Sidebar />
            <div className="flex-grow p-8 flex-1 overflow-y-auto">
                <h2 className="text-3xl font-texta-heavy text-mosaico mb-8">Nova Categoria</h2>

                <form onSubmit={handleRegister}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaBookOpen className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Nome</span>
                            </label>
                            <input
                                type="text"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaUpload className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Imagem (Caption)</span>
                            </label>
                            <input
                                type="file"
                                required
                                onChange={(e) => setCaption(e.target.files ? e.target.files[0] : null)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaEye className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Visível</span>
                            </label>
                            <select
                                required
                                value={visible ? "true" : "false"}
                                onChange={(e) => setVisible(e.target.value === "true")}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            >
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="bg-mosaico text-white font-semibold py-2 px-4 rounded-md hover:bg-mosaico-dark transition duration-200"
                            disabled={loading}
                        >
                            {loading ? 'Criando...' : 'Criar Categoria'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminCategoriesCreatePage;