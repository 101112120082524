import React, { useState, useEffect } from 'react';
import Sidebar from '../../../components/Sidebar';
import { FaLock, FaPhoneAlt, FaUser, FaEnvelope, FaBuilding, FaMapMarkerAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber, formatCPF, formatCNPJ } from '../../../components/functions/functions';
import { toast } from 'react-toastify';
import axios from 'axios'; // Importar AxiosError
import { fetchLocalities } from '../../../services/localityService';
import { fetchBranchTypes } from '../../../services/branchTypesService';
import { createUserByAdmin } from '../../../services/userService';
import { registerCompany } from '../../../services/companyService';


const AdminUsersCreatePage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCPF] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('user');
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [state_registration, setSateRegistration] = useState('');
  const [phoneCompany, setPhoneCompany] = useState('');
  const [activeTab, setActiveTab] = useState<'personal' | 'company'>('personal');
  const navigate = useNavigate();
  const [localities, setLocalities] = useState<{ [state: string]: string[] }>({});
  const [branchTypes, setBranchTypes] = useState<{ id: string, name: string, visible: boolean }[]>([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedBranchType, setSelectedBranchType] = useState('');

  useEffect(() => {
    // Atualiza a aba ativa com base na role
    if (role === 'company') {
      setActiveTab('company');
    } else {
      setActiveTab('personal');
    }
  }, [role]);

  useEffect(() => {
    fetchAndSetLocalities();
    fetchAndSetBranchTypes();
  }, []);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPhone(formatPhoneNumber(value));
  };

  const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setCPF(formatCPF(value));
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };
  
  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRole = e.target.value;
    setRole(newRole);
  };

  const handleTabChange = (tab: 'personal' | 'company') => {
    setActiveTab(tab);
  };

  const fetchAndSetLocalities = async () => {
    try {
      const localitiesData = await fetchLocalities();
      setLocalities(localitiesData);
    } catch (error) {
      toast.error('Erro ao buscar localidades!');
    }
  };

  const fetchAndSetBranchTypes = async () => {
    try {
      const branchTypesData = await fetchBranchTypes();
      setBranchTypes(branchTypesData);
    } catch (error) {
      toast.error('Erro ao buscar localidades!');
    }
  }

  const handleCNPJChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedCNPJ = formatCNPJ(value);
    setCNPJ(formattedCNPJ);
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await createUserByAdmin(
        name, cpf.replace(/\D/g, ''), phone, role, email, password, true, true, true
      );

      const userId = response.data.user_id;

      console.log("User ID", userId);
      if (userId) {
        toast.success("Usuário cadastrado com sucesso!");

        if(role === 'company'){
            try{
                const response = await registerCompany(companyName, companyAddress, phoneCompany.replace(/\D/g, ''), selectedCity, selectedState, selectedBranchType, state_registration, cnpj.replace(/\D/g, ''), userId)
                if (response.status === 200) {
                    toast.success('Empresa registrada com sucesso!');
                    navigate('/admin/users');
                }else{
                    const errorMessage = response.message || 'Ocorreu um erro durante o registro.';
                    toast.error(errorMessage);
                }
            }catch (error: unknown) {
                // Checa se o erro é um AxiosError
                if (axios.isAxiosError(error)) {
                    // Aqui, você pode acessar a resposta de erro da API
                    if (error.response) {
                    toast.error(`Erro: ${error.response.data.message || 'Erro desconhecido.'}`);
                    } else {
                    // Se não houver resposta, pode ser um erro de configuração ou rede
                    toast.error('Erro de rede ou configuração.');
                    }
                } else {
                    // Trata erros não esperados
                    toast.error('Erro desconhecido ao tentar cadastrar a empresa.');
                }
            }
        }else{
            navigate('/admin/users');
        }
      } else {
        toast.error("Erro ao cadastrar o usuário.");
      }
    } catch (error: unknown) {
      // Checa se o erro é um AxiosError
      if (axios.isAxiosError(error)) {
        // Aqui, você pode acessar a resposta de erro da API
        if (error.response) {
          toast.error(`Erro: ${error.response.data.message || 'Erro desconhecido.'}`);
        } else {
          // Se não houver resposta, pode ser um erro de configuração ou rede
          toast.error('Erro de rede ou configuração.');
        }
      } else {
        // Trata erros não esperados
        toast.error('Erro desconhecido ao tentar cadastrar o usuário.');
      }
    }
  };



  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />

      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        <div className="rounded-lg p-6 md:p-8 lg:p-10">
          <h2 className="text-3xl font-texta-heavy text-mosaico mb-8">Novo Usuário</h2>

          {/* Navegação entre abas */}
          <div className="mb-8">
            <div className="flex space-x-4 border-b border-gray-300">
              <button
                onClick={() => handleTabChange('personal')}
                className={`py-2 px-4 text-sm font-semibold ${activeTab === 'personal' ? 'border-b-2 border-mosaico text-mosaico' : 'text-gray-600'}`}
              >
                Dados Pessoais
              </button>
              {role === 'company' && (
                <button
                  onClick={() => handleTabChange('company')}
                  className={`py-2 px-4 text-sm font-semibold ${activeTab === 'company' ? 'border-b-2 border-mosaico text-mosaico' : 'text-gray-600'}`}
                >
                  Dados da Empresa
                </button>
              )}
            </div>
          </div>

          <form onSubmit={handleRegister}>
            {/* Dados Pessoais */}
            {activeTab === 'personal' && (
              <div className="mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaUser className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Nome</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaEnvelope className="text-mosaico" />
                      <span className="text-gray-700 font-medium">E-mail</span>
                    </label>
                    <input
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaLock className="text-mosaico" />
                      <span className="text-gray-700 font-medium">CPF</span>
                    </label>
                    <input
                      required  
                      type="text"
                      value={cpf}
                      onChange={handleCPFChange}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaPhoneAlt className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Telefone</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={phone}
                      onChange={handlePhoneChange}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaLock className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Senha</span>
                    </label>
                    <input
                    required
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaLock className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Confirmar Senha</span>
                    </label>
                    <input
                      type="password"
                      required
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>
                </div>

                <div className="mt-6">
                  <label className="block font-texta-regular text-gray-700 mb-2">Cadastrar Como</label>
                  <div className="flex space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="role"
                        value="user"
                        required
                        checked={role === 'user'}
                        onChange={handleRoleChange}
                        className="form-radio text-mosaico focus:ring-mosaico"
                      />
                      <span className="ml-2 font-texta-regular text-sm">Usuário</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="role"
                        value="admin"
                        required
                        checked={role === 'admin'}
                        onChange={handleRoleChange}
                        className="form-radio text-mosaico focus:ring-mosaico"
                      />
                      <span className="ml-2 font-texta-regular text-sm">Administrador</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="role"
                        value="company"
                        required
                        checked={role === 'company'}
                        onChange={handleRoleChange}
                        className="form-radio text-mosaico focus:ring-mosaico"
                      />
                      <span className="ml-2 font-texta-regular text-sm">Parceiro</span>
                    </label>
                  </div>
                </div>
              </div>
            )}

            {/* Dados da Empresa */}
            {role === 'company' && activeTab === 'company' && (
              <div className="mt-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Nome da Empresa</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">CNPJ</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={cnpj}
                      onChange={handleCNPJChange}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Inscriçao Estadual</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={state_registration}
                      onChange={(e) => setSateRegistration(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaPhoneAlt className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Telefone da Empresa</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={phoneCompany}
                      onChange={(e) => setPhoneCompany(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>
                  

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaMapMarkerAlt className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Endereço</span>
                    </label>
                    <input
                      type="text"
                      required
                      value={companyAddress}
                      onChange={(e) => setCompanyAddress(e.target.value)}
                      className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                    />
                  </div>

                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Tipo de Ramo</span>
                    </label>
                    <select
                  id="branch_type"
                  value={selectedBranchType}
                  onChange={(e) => setSelectedBranchType(e.target.value)}
                  className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                  required
                >
                  <option value="">Selecione um tipo de ramo</option>
                  {branchTypes.filter(branch => branch.visible).map((branchType) => (
                    <option key={branchType.id} value={branchType.id}>
                      {branchType.name}
                    </option>
                  ))}
                </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Estado</span>
                    </label>
                    <select
                  id="state"
                  value={selectedState}
                  onChange={handleStateChange}
                  className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                  required
                >
                  <option value="">Selecione um estado</option>
                  {Object.keys(localities).map((state) => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="flex items-center space-x-2 mb-2">
                      <FaBuilding className="text-mosaico" />
                      <span className="text-gray-700 font-medium">Cidade</span>
                    </label>
                    <select
                  id="city"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                  disabled={!selectedState}
                  required
                >
                  <option value="">Selecione uma cidade</option>
                  {selectedState && localities[selectedState]?.map((city) => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-8 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => navigate('/admin/users')}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-mosaico text-white rounded-md hover:bg-mosaico-dark focus:outline-none"
              >
                {activeTab === 'company' ? 'Salvar Empresa' : 'Cadastrar'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminUsersCreatePage;
