import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSignInAlt, FaSearch } from 'react-icons/fa';

const AppBar: React.FC = () => {
  const location = useLocation();
  const isLoggedIn = !!sessionStorage.getItem('authToken');

  if (location.pathname === '/') {
    return (
      <div className="bg-appbar text-white p-4 flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/" className="text-xl font-bold">
            <img
              src="/logo.png" 
              alt="Logo"
              className="h-16 w-auto" 
            />
          </Link>
        </div>

        {!isLoggedIn && (
          <div className="relative flex items-center mx-auto">
            <input
              type="text"
              placeholder="O que você procura?"
              className="py-1 px-4 rounded-md border text-grayDark border-gray-300 pr-12 w-80 focus:outline-none"
            />
            <button className="absolute inset-y-0 right-0 flex items-center justify-center p-2 bg-white-500 text-grayDark rounded-r-md">
              <FaSearch />
            </button>
          </div>
        )}

        <div>
          {!isLoggedIn && (
            <Link to="/login">
              <button className="flex items-center bg-white text-grayDark p-2 rounded-md shadow-sm">
                <FaSignInAlt className="mr-2" />
                Entrar
              </button>
            </Link>
          )}
        </div>
      </div>
    );
  }else{
    return null;
  }
};


export default AppBar;
