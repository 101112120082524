import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import { FaBookOpen, FaUpload, FaEye, FaMoneyBill } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createPlan } from '../../../services/planService';

const AdminPlansCreatePage: React.FC = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [payment_billing, setPaymentBilling] = useState('semanal');
    const [role, setRole] = useState('user');
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const priceNumber = parseFloat(price);

        // Verifique se a conversão foi bem-sucedida
        if (isNaN(priceNumber)) {
            toast.error('Invalid price format');
        }

            const response = await createPlan(name, visible, priceNumber, description, payment_billing, role);
            toast.success(response.message);
            navigate('/admin/planos');
        } catch (error) {
            toast.error('Failed to create plan.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100 font-texta-regular">
            <Sidebar />
            <div className="flex-grow p-8 flex-1 overflow-y-auto">
                <h2 className="text-3xl font-texta-heavy text-mosaico mb-8">Novo Plano</h2>

                <form onSubmit={handleRegister}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaBookOpen className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Nome</span>
                            </label>
                            <input
                                type="text"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaEye className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Visível</span>
                            </label>
                            <select
                                required
                                value={visible ? "true" : "false"}
                                onChange={(e) => setVisible(e.target.value === "true")}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            >
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaMoneyBill className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Preço</span>
                            </label>
                            <input
                                type="text"
                                required
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaEye className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Recorrência de Pagamento</span>
                            </label>
                            <select
                                required
                                value={payment_billing}
                                onChange={(e) => setPaymentBilling(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            >
                                <option value="semanal">Semanal</option>
                                <option value="mensal">Mensal</option>
                                <option value="anual">Anual</option>
                            </select>
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaEye className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Plano para</span>
                            </label>
                            <select
                                required
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            >
                                <option value="user">Cliente</option>
                                <option value="company">Parceiro</option>
                            </select>
                        </div>
                        <div className="flex flex-col col-span-1 md:col-span-2"> {/* Altere a coluna para ocupar a largura total em telas maiores */}
                            <label className="flex items-center space-x-2 mb-2">
                                <FaMoneyBill className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Descrição</span>
                            </label>
                            <textarea
                                rows={4}
                        
                                required
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                
                                className="w-full resize-none bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="bg-mosaico text-white font-semibold py-2 px-4 rounded-md hover:bg-mosaico-dark transition duration-200"
                            disabled={loading}
                        >
                            {loading ? 'Criando...' : 'Criar Plano'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminPlansCreatePage;
