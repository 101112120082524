import { api } from './axios';
import { BASE_URL } from '../config/config';


interface LoginResponse {
    status: number;
    message: string;
    data: {
        token: string | null;
    };
}

export const login = async (email: string, password: string, role: string) => {
    try {
        const response = await api.post<LoginResponse>('auth', { email, password, role });
        return response.data;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
};





export const getCurrentUser = async () => {
    try {

        console.log(localStorage.getItem('token'));
        const response = await api.get(`/user/my`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch user information.');
    }
};
