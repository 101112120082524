
import React from 'react';

const UnauthorizedPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h1 className="text-2xl font-bold text-red-500">Acesso Negado</h1>
                <p className="mt-2 text-gray-600">Você não tem permissão para acessar esta página.</p>
            </div>
        </div>
    );
}

export default UnauthorizedPage;
