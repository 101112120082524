import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import { FaUser, FaEnvelope, FaPhoneAlt, FaIdCard, FaCalendarAlt, FaBuilding, FaMapMarked, FaTag } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { User } from '../../../interfaces/user_interface';
import { fetchUserDetail } from '../../../services/userService';

const AdminUserDetailPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const getUserDetail = async () => {
      try {
        const userData = await fetchUserDetail(userId!);
        console.log("User API Response:", userData);

        if (userData.data && userData.data.user) {
          setUser(userData.data.user);
        } else {
          console.error("User data is missing in API response");
          toast.error('Dados do usuário não encontrados.');
        }
      } catch (err) {
        toast.error('Erro ao carregar os dados.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getUserDetail();
  }, [userId]);

  // Format locality field if parent_id is present
  const formatLocality = (locality: any) => {
    if (locality.parent_id) {
      return `${locality.name}/${locality.parent.name}`;
    }
    return locality.name;
  };

  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />
      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        {loading ? (
          <p className="text-gray-600">Carregando...</p>
        ) : user ? (
          <div className="w-full rounded-md p-6">
            <div className="flex items-center border-b pb-4 mb-6">
              <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
                <img src="https://via.placeholder.com/100" alt={user.role === 'company' ? 'Company Logo' : 'Avatar'} className="w-full h-full object-cover" />
              </div>
              <div className="ml-6">
                <h2 className="text-3xl font-semibold text-mosaico">{user.name}</h2>
                <p className="text-lg text-gray-600">{user.role}</p>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col  ">
                <div className="flex items-center space-x-2">
                  <FaUser className="text-gray-500" />
                  <label className="text-gray-700 font-medium">
                    Nome
                  </label>
                </div>
                <input type="text" value={user.name} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
              </div>
              <div className="flex flex-col  ">
                <div className="flex items-center space-x-2">
                  <FaEnvelope className="text-gray-500" />
                  <label className="text-gray-700 font-medium">
                    Email
                  </label>
                </div>
                <input type="text" value={user.email} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
              </div>
              <div className="flex flex-col  ">
                <div className="flex items-center space-x-2">
                  <FaPhoneAlt className="text-gray-500" />
                  <label className="text-gray-700 font-medium">
                    Telefone
                  </label>
                </div>
                <input type="text" value={user.phone} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
              </div>
              <div className="flex flex-col  ">
                <div className="flex items-center space-x-2">
                  <FaIdCard className="text-gray-500" />
                  <label className="text-gray-700 font-medium">
                    CPF
                  </label>
                </div>
                <input type="text" value={user.cpf} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
              </div>
              <div className="flex flex-col  ">
                <div className="flex items-center space-x-2">
                  <FaCalendarAlt className="text-gray-500" />
                  <label className="text-gray-700 font-medium">
                    Data de Cadastro
                  </label>
                </div>
                <input type="text" value={new Date(user.created_at).toLocaleDateString()} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
              </div>
            </div>
            {user.company && user.role === 'company' && (
              <>
                <div className="mt-8">
                  <h3 className="text-2xl font-semibold text-mosaico mb-4">Detalhes da Empresa</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaBuilding className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Nome da Empresa
                        </label>
                      </div>
                      <input type="text" value={user.company.company_name} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaMapMarked className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Endereço
                        </label>
                      </div>
                      <input type="text" value={user.company.address} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaTag className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          CNPJ
                        </label>
                      </div>
                      <input type="text" value={user.company.cnpj} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaTag className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                        Inscrição  Estadual
                        </label>
                      </div>
                      <input type="text" value={user.company.state_registration} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaPhoneAlt className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Telefone
                        </label>
                      </div>
                      <input type="text" value={user.company.phone} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaCalendarAlt className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Data de Cadastro
                        </label>
                      </div>
                      <input type="text" value={new Date(user.company.created_at).toLocaleDateString()} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaTag className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Tipo de Ramo
                        </label>
                      </div>
                      <input type="text" value={user.company.branch_type.name} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                    <div className="flex flex-col  ">
                      <div className="flex items-center space-x-2">
                        <FaMapMarked className="text-gray-500" />
                        <label className="text-gray-700 font-medium">
                          Localidade
                        </label>
                      </div>
                      <input type="text" value={formatLocality(user.company.locality)} readOnly className="w-full bg-gray-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <p className="text-gray-600">Nenhum dado encontrado.</p>
        )}
      </div>
    </div>
  );
}

export default AdminUserDetailPage;
