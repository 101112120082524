import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import CategoryItem from './CategoryItem';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Category } from '../interfaces/category_interface';
import { fetchCategories } from '../services/categoryService';



const CategoryCarousel: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
   const token = sessionStorage.getItem('authToken');
   console.log("Token " + token);
      fetchCategories().then(response => {
        if (response.status === 200) {
          setCategories(response.data.categories);
        } else {
          setError(response.message);
        }
      }).catch(() => {
        setError('Failed to fetch categories.');
      });

  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      }
    ]
  };

  return (
    <div className="p-4">
      {error && <div className="text-red-500">{error}</div>}
      <Slider {...settings}>
        {categories.map((category) => (
          <div key={category.id} className="p-2">
            <CategoryItem title={category.name} imageUrl={category.caption} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CategoryCarousel;
