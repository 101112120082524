import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelope, FaLock, FaUser, FaPhoneAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import { formatPhoneNumber, formatCPF } from '../components/functions/functions';
import RedirectIfLoggedIn from '../components/RedirectToLogin';
import { registerUser } from '../services/userService';
import { login } from '../services/authService';


const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCPF] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [notifySMS, setNotifySMS] = useState(false);
  const [notifyWhatsApp, setNotifyWhatsApp] = useState(false);
  const [terms, setTerms] = useState(false);
  const [role, setRole] = useState('user');

  const navigate = useNavigate();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedPhone = formatPhoneNumber(value);
    setPhone(formattedPhone);
  };
  
  const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formattedCPF = formatCPF(value);
    setCPF(formattedCPF);
  };
  

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const response = await registerUser(
        name,
        cpf.replace(/\D/g, ''), 
        phone.replace(/\D/g, ''), 
        role,
        email,
        password,
        notifyWhatsApp,
        notifySMS,
        notifyEmail
      );
  
      if (response.status === 200) {
        toast.success('Registro realizado com sucesso!');

        if(role === 'user'){
          navigate('/login');
        }else if(role === 'company'){
          const response = await login(email, password, role);
            if (response.status === 200 && response.data.token) {
              sessionStorage.setItem('authToken', response.data.token);
              sessionStorage.setItem('role', role );
              toast.success('Login realizado com sucesso!');
              navigate('/register/parceiro');
            } else {
              toast.error(response.message || 'Ocorreu um erro durante o login.');
            }
        }
        
      } else {
        const errorMessage = response.message || 'Ocorreu um erro durante o registro.';
        toast.error(errorMessage);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data;
  
        if (errorResponse && typeof errorResponse === 'object' && 'errors' in errorResponse) {
          const errorMessages = Object.values(errorResponse.errors)
            .flat()
            .join(' ');
  
          toast.error(`Erro: ${errorMessages}`);
        } else {
          toast.error(error.response?.data?.message || 'Ocorreu um erro durante o registro.');
        }
      } else {
        toast.error('Ocorreu um erro durante o registro.');
      }
      console.error('Error during registration:', error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const navigateLogin = () => {
    navigate('/login');
  }

  return (
    <div className="flex min-h-screen">
      <RedirectIfLoggedIn /> 
      <div className="hidden md:flex items-center justify-center w-1/2 bg-mosaico">
        <img src='./mosaico_teste.png' alt='Logo' className='w-1/2 h-auto' />
      </div>
      <div className="flex items-center justify-center w-full md:w-1/2 bg-white">
        <div className="p-6 rounded-lg w-full max-w-xl">
          <div className="flex justify-center mb-4">
            <img src="./logo.png" alt="Logo" className="h-20 w-auto" />
          </div>          
          <h2 className="text-2xl font-texta-heavy">Bem Vindo(a) ao MosaicoPlus</h2>
          <h2 className="text-xs font-texta-regular mb-4">Faça seu cadastro e obtenha acesso aos recursos do nosso clube de benefícios.</h2>
          
          <form onSubmit={handleRegister}>
            <div className="flex mb-4">
                <div className="w-1/2 pr-2">
                    <label htmlFor="name" className="block text-sm font-texta-regular text-gray-700">
                    Nome
                    </label>
                    <div className="relative">
                    <input
                        id="name"
                        type="text"
                        placeholder="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10 "
                        required
                    />
                    <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
                <div className="w-1/2 pl-2">
                    <label htmlFor="email" className="block text-sm font-texta-regular text-gray-700">
                    Email
                    </label>
                    <div className="relative">
                    <input
                        id="email"
                        type="email"
                        placeholder="email@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                        required
                    />
                    <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
            </div>

            <div className="flex mb-4">
                <div className="w-1/2 pr-2">
                    <label htmlFor="phone" className="block text-sm font-texta-regular text-gray-700">
                    Telefone
                    </label>
                    <div className="relative">
                    <input
                        id="phone"
                        type="text"
                        placeholder="Telefone"
                        value={phone}
                        onChange={handlePhoneChange}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                        required
                    />
                    <FaPhoneAlt className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
                <div className="w-1/2 pl-2">
                    <label htmlFor="cpf" className="block text-sm font-texta-regular text-gray-700">
                    CPF
                    </label>
                    <div className="relative">
                    <input
                        id="cpf"
                        type="text"
                        placeholder="000.000.000-00"
                        value={cpf}
                        onChange={handleCPFChange}
                        className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                        required
                    />
                    <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    </div>
                </div>
            </div>

            <div className="flex mb-4">
                <div className="w-1/2 pr-2">
                    <label htmlFor="password" className="block text-sm font-texta-regular text-gray-700">
                    Senha
                </label>
                <div className="relative">
                    <input
                    id="password"
                    type="password"
                    placeholder='Sua Senha'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                    required
                    />
                    <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                </div>
                <div className="w-1/2 pl-2">
                    <label htmlFor="confirm_password" className="block text-sm font-texta-regular text-gray-700">
                    Confirmar Senha
                </label>
                <div className="relative">
                    <input
                    id="confirm_password"
                    type="password"
                    placeholder='Confirme sua senha'
                    value={confirm_password}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                    required
                    />
                    <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                </div>
            </div>

            <div className="mb-4">
              <label className="block font-texta-regular text-gray-700">Cadastrar Como</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="user"
                    checked={role === 'user'}
                    onChange={() => setRole('user')}
                    className="font-texta-regular form-radio text-mosaico checked:bg-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Usuário</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="company"
                    checked={role === 'company'}
                    onChange={() => setRole('company')}
                    className="font-texta-regular form-radio text-mosaico checked:bg-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Parceiro</span>
                </label>
              </div>
            </div>

            <div className="mb-4">
              <span className="block font-texta-regular text-gray-700 mb-2">Receber Notificações via</span>
              <div className="flex flex-wrap">
                <label className="flex items-center mr-4 mb-2">
                  <input
                    type="checkbox"
                    checked={notifyEmail}
                    onChange={(e) => setNotifyEmail(e.target.checked)}
                    className="font-texta-regular form-checkbox text-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Email</span>
                </label>
                <label className="flex items-center mr-4 mb-2">
                  <input
                    type="checkbox"
                    checked={notifySMS}
                    onChange={(e) => setNotifySMS(e.target.checked)}
                    className="font-texta-regular form-checkbox text-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">SMS</span>
                </label>
                <label className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    checked={notifyWhatsApp}
                    onChange={(e) => setNotifyWhatsApp(e.target.checked)}
                    className="font-texta-regular form-checkbox text-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">WhatsApp</span>
                </label>
              </div>
            </div>

            <div className="flex items-center mb-4">
              <input
                id="terms"
                type="checkbox"
                checked={terms}
                onChange={(e) => setTerms(e.target.checked)}
                className="font-texta-regular form-checkbox text-mosaico focus:ring-mosaico"
                required
              />
              <label htmlFor="terms" className="ml-2 font-texta-regular text-sm text-gray-700">
                Eu concordo com os <a href="/terrmos" className="text-mosaico">Termos e Condições</a>
              </label>
            </div>

            <button
              type="submit"
              className="font-texta-heavy w-full bg-mosaico border border-mosaico text-white p-2 rounded-md shadow-sm hover:bg-white hover:text-mosaico hover:border-mosaico"
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Registrar'}
            </button>
            <div className="flex items-center justify-center mt-2">
                <span className="text-black font-texta-regular">
                    Já possui uma conta?{' '}
                </span>
                <button
                    onClick={navigateLogin}
                    className="text-mosaico hover:underline font-texta-bold ml-1"
                >Faça login
                </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
