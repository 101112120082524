import React, { useEffect, useState } from 'react';
import { FaBookOpen, FaSearch, FaTrash, FaEdit, FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import { deleteCategory } from '../../services/categoryService';
import Sidebar from '../../components/Sidebar';
import { BASE_URL } from '../../config/config';
import { Cupom } from '../../interfaces/cupom_interface';
import { fetchCupoms } from '../../services/cupom_services';

const CupomsPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const [cupoms, setCupoms] = useState<Cupom[]>([]);
  const [filteredCupoms, setFilteredCupoms] = useState<Cupom[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [cupomIdToDelete, setCupomIdToDelete] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCupoms();
        console.log(response);
        if (response.status === 200) {
          setCupoms(response.data);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error('Failed to fetch categories.');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      if (!cupoms) return;

      const filtered = cupoms.filter(cupom => {
        const matchesSearch = cupom.cupom_code.toLowerCase().includes(search.toLowerCase());
        return matchesSearch;
      });

      setFilteredCupoms(filtered);
      setPageCount(Math.ceil(filtered.length / 15));
    };

    applyFilters();
  }, [cupoms, search]);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
  };

  const handleCreate = () => {
    navigate('/cupoms/create');
  };

  const handleDelete = (cupomId: number) => {
    setCupomIdToDelete(cupomId);
    setModalVisible(true);
  };

  const confirmDelete = async () => {
    if (cupomIdToDelete !== null) {
      try {
        const response = await deleteCategory(cupomIdToDelete);
        if (response.status === 200) {
          toast.success(response.message);
          setModalVisible(false);
          setCupoms(cupoms.filter(cupom => cupom.id !== cupomIdToDelete));
          setFilteredCupoms(filteredCupoms.filter(cupom => cupom.id !== cupomIdToDelete));
        } else {
          toast.error(response.message || 'Failed to delete category.');
        }
      } catch (error) {
        toast.error('Failed to delete category.');
      }
    }
  };

  const handleViewImage = (imageUrl: string) => {
    setImageSrc(imageUrl);
    setImageModalVisible(true);
  };

  const handleEdit = (id: number) => {
    navigate(`/admin/categories/create/${id}`);
  };

  // Nova função para navegação
  const handleCompanyNameClick = (userId: number) => {
    navigate(`/admin/users/${userId}`);
  };

  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />

      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        {/* Navbar */}
        <nav className="bg-gray-100 px-6 py-4 flex justify-between items-center w-100">
          <div className="relative flex-grow">
            <input
              id="search"
              type="text"
              placeholder="Busque aqui..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
              required
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
        </nav>

        {/* Filtros */}
        <div className="flex flex-col flex-grow p-6 bg-gray-100">
          <div className="flex justify-start mb-4">
            <button type="submit" onClick={handleCreate} className='bg-mosaico text-white px-4 py-2 rounded-md flex items-center space-x-2'>
              <FaBookOpen size={16} />
              <span>Novo Cupom</span> 
            </button>
          </div>

          <h2 className="text-2xl font-texta-regular text-gray-700 mb-6">Categorias</h2>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 text-center">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Codigo do Cupom</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Categoria</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Icone</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Empresa</th>
                  <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredCupoms.slice(currentPage * 15, (currentPage + 1) * 15).map(cupom => (
                  <tr key={cupom.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{cupom.cupom_code}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{cupom.category.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => handleViewImage(`${BASE_URL}${cupom.caption}`)}
                        className="text-mosaico hover:underline"
                      >
                        Ver Imagem
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap underline text-sm text-mosaico cursor-pointer" onClick={() => handleCompanyNameClick(cupom.company.user_id)}>
                      {cupom.company.company_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-center">
                      {/* Adicione suas ações aqui */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-center mt-4">
            <ReactPaginate
              previousLabel={'←'}
              nextLabel={'→'}
              breakLabel={'...'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'flex space-x-1'}
              pageClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              pageLinkClassName={'text-sm'}
              previousClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              previousLinkClassName={'text-sm '}
              nextClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded cursor-pointer'}
              nextLinkClassName={'text-sm '}
              breakClassName={'w-10 h-10 flex items-center justify-center border border-gray-300 rounded'}
              breakLinkClassName={'text-sm'}
              activeClassName={'bg-mosaico text-white'}
            />
          </div>
        </div>

        {/* Modal de Confirmação de Exclusão */}
        {modalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-md shadow-md">
              <h3 className="text-lg font-semibold">Confirmar Exclusão</h3>
              <p>Você tem certeza que deseja excluir este cupom?</p>
              <div className="flex justify-end mt-4">
                <button onClick={() => setModalVisible(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2">Cancelar</button>
                <button onClick={confirmDelete} className="bg-red-500 text-white px-4 py-2 rounded-md">Excluir</button>
              </div>
            </div>
          </div>
        )}

        {/* Modal de Imagem */}
        {imageModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-md shadow-md">
              <img src={imageSrc} alt="Imagem do Cupom" className="max-w-full h-auto" />
              <button onClick={() => setImageModalVisible(false)} className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mt-4">Fechar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CupomsPage;
