import React from 'react';
import { BASE_URL } from '../config/config';

interface CategoryItemProps {
  title: string;
  imageUrl: string;
}

const CategoryItem: React.FC<CategoryItemProps> = ({ title, imageUrl }) => {


  return (
    <div className="flex flex-col items-center">
      <div className="w-20 h-20 mb-2">
      <img src={`${BASE_URL}${imageUrl}`} alt={title} className="w-full h-full object-cover rounded-full" />
      </div>
      <h3 className="text-center text-sm">{title}</h3>
    </div>
  );
};

export default CategoryItem;
