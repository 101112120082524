import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import { FaUser, FaEnvelope, FaPhoneAlt, FaIdCard, FaBuilding, FaMapMarked, FaTag, FaLock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { User } from '../../../interfaces/user_interface';
import { formatCPF, formatPhoneNumber } from '../../../components/functions/functions';
import { fetchLocalities } from '../../../services/localityService';
import { fetchUserDetail, updateUserByAdmin } from '../../../services/userService';
import { updateCompanyByAdmin } from '../../../services/companyService';
import { fetchBranchTypes } from '../../../services/branchTypesService';

const AdminUserEditPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [branchTypes, setBranchTypes] = useState<{ id: string, name: string, visible: boolean }[]>([]);
  const [localities, setLocalities] = useState<{ [state: string]: string[] }>({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [roleUpdate, setRoleUpdate] = useState('');


  const fetchAndSetBranchTypes = async () => {
    try {
      const branchTypesData = await fetchBranchTypes();
      setBranchTypes(branchTypesData);
    } catch (error) {
      toast.error('Erro ao buscar localidades!');
    }
  }

  const fetchAndSetLocalities = async () => {
    try {
      const localitiesData = await fetchLocalities();
      setLocalities(localitiesData);
    } catch (error) {
      toast.error('Erro ao buscar localidades!');
    }
  };

  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  

  const navigate = useNavigate();

  useEffect(() => {
    const getUserDetail = async () => {
      try {
        const userData = await fetchUserDetail(userId!);
        console.log("User API Response:", userData);

        if (userData.data && userData.data.user) {
          setUser(userData.data.user);
          if(userData.data.user.role === 'company'){
            setSelectedCity(userData.data.user.company.locality.name);
            setSelectedState(userData.data.user.company.locality.parent.name);
          }
          setRoleUpdate(userData.data.user.role);
        } else {
          console.error("User data is missing in API response");
          toast.error('Dados do usuário não encontrados.');
        }
      } catch (err) {
        toast.error('Erro ao carregar os dados.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getUserDetail();
    fetchAndSetBranchTypes();
    fetchAndSetLocalities();
  }, [userId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (user) {
      const { name, value } = e.target;

      if (name === 'name') {
        setUser({ ...user, name: value });
      } else if (name === 'email') {
        setUser({ ...user, email: value });
      } else if (name === 'phone') {
        setUser({ ...user, phone: formatPhoneNumber(value) });
      } else if (name === 'cpf') {
        setUser({ ...user, cpf: formatCPF(value) });
      } else if (name === 'role') {
        setRoleUpdate(value);
      } else if (user.company) {
        if (name === 'company_name') {
          setUser({ ...user, company: { ...user.company, company_name: value } });
        } else if (name === 'address') {
          setUser({ ...user, company: { ...user.company, address: value } });
        } else if (name === 'cnpj') {
          setUser({ ...user, company: { ...user.company, cnpj: value } });
        } else if (name === 'company_phone') {
          setUser({ ...user, company: { ...user.company, phone: value } });
        }else if (name === 'state_registration') {
            setUser({ ...user, company: { ...user.company, state_registration: value } });
        }else if(name === 'branch_type_id'){
            setUser({ ...user, company: { ...user.company, branch_type_id: parseInt(value, 10) } });
        }
      }
    }
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingButton(true);

    try {
        const response = await updateUserByAdmin(user!, roleUpdate);
        const { status, message } = response;

        if (status === 200) {
            toast.success(message || 'Usuário atualizado com sucesso');
        } else if (status === 404) {
            toast.error(message || 'Usuário não encontrado');
        } else if (status === 403) {
            toast.error(message || 'Você não tem permissão para atualizar este usuário');
        } else if (status === 401) {
            toast.error(message || 'Usuário não autenticado');
        } else {
            toast.error('Erro desconhecido');
        }
    } catch (error) {
        toast.error('Erro ao atualizar o usuário');
    }

    if (user?.role === 'company') {
        try {
            const response = await updateCompanyByAdmin(user.company!, selectedState, selectedCity);
            const { status, message } = response;

            if (status === 200) {
                toast.success(message || 'Empresa atualizada com sucesso');
            } else if (status === 404) {
                toast.error(message || 'Empresa não encontrada');
            } else if (status === 403) {
                toast.error(message || 'Você não tem permissão para atualizar a Empresa');
            } else if (status === 401) {
                toast.error(message || 'Usuário não autenticado');
            } else {
                toast.error('Erro desconhecido');
            }
        } catch (error) {
            toast.error('Erro ao atualizar a empresa');
        }
    }

    navigate(`/admin/users/edit/${userId}`);
    window.location.reload();

    setLoadingButton(false);
};



  return (
    <div className="flex h-screen bg-gray-100 font-texta-regular">
      <Sidebar />
      
      <div className="flex-grow p-8 flex-1 overflow-y-auto">
        <form onSubmit={handleSubmitForm}>
          {loading ? (
            <p className="text-gray-600">Carregando...</p>
          ) : user ? (
            <div className="w-full rounded-md p-6">
              <div className="flex items-center border-b pb-4 mb-6">
                <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
                  <img src="https://via.placeholder.com/100" alt={user.role === 'company' ? 'Company Logo' : 'Avatar'} className="w-full h-full object-cover" />
                </div>
                <div className="ml-6">
                  <h2 className="text-3xl font-semibold text-mosaico">{user.name}</h2>
                  <p className="text-lg text-gray-600">{user.role}</p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <FaUser className="text-gray-500" />
                    <label className="text-gray-700 font-medium">Nome</label>
                  </div>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={user.name}
                    className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <FaEnvelope className="text-gray-500" />
                    <label className="text-gray-700 font-medium">Email</label>
                  </div>
                  <input
                    type="text"
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                    className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <FaPhoneAlt className="text-gray-500" />
                    <label className="text-gray-700 font-medium">Telefone</label>
                  </div>
                  <input
                    type="text"
                    name="phone"
                    onChange={handleChange}
                    value={user.phone}
                    className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <FaIdCard className="text-gray-500" />
                    <label className="text-gray-700 font-medium">CPF</label>
                  </div>
                  <input
                    type="text"
                    name="cpf"
                    onChange={handleChange}
                    value={user.cpf}
                    className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <FaLock className="text-gray-500" />
                    <label className="text-gray-700 font-medium">Acesso</label>
                  </div>
                  <select
                    name="role"
                    value={roleUpdate}
                    onChange={handleChange}
                    className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                  >
                    <option value="user">Usuário</option>
                    <option value="company">Empresa</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
              {user.company && user.role === 'company' && (
                <>
                  <div className="mt-8">
                    <h3 className="text-2xl font-semibold text-mosaico">Detalhes da Empresa</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaBuilding className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Nome da Empresa</label>
                        </div>
                        <input
                          type="text"
                          name="company_name"
                          value={user.company.company_name}
                          onChange={handleChange}
                          className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaMapMarked className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Endereço</label>
                        </div>
                        <input
                          type="text"
                          name="address"
                          value={user.company.address}
                          onChange={handleChange}
                          className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaTag className="text-gray-500" />
                          <label className="text-gray-700 font-medium">CNPJ</label>
                        </div>
                        <input
                          type="text"
                          name="cnpj"
                          value={user.company.cnpj}
                          onChange={handleChange}
                          className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaTag className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Inscrição Estadual</label>
                        </div>
                        <input
                          type="text"
                          name="state_registration"
                          value={user.company.state_registration}
                          onChange={handleChange}
                          className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaPhoneAlt className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Telefone da Empresa</label>
                        </div>
                        <input
                          type="text"
                          name="company_phone"
                          value={user.company.phone}
                          onChange={handleChange}
                          className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaTag className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Tipo de Ramo</label>
                        </div>
                        <select
                            id="branch_type"
                            name='branch_type_id'
                            value={user.company.branch_type_id}
                            onChange={handleChange}
                            className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                            required
                            >
                            <option value="">Selecione um tipo de ramo</option>
                            {branchTypes.filter(branch => branch.visible).map((branchType) => (
                                <option key={branchType.id} value={branchType.id}>
                                {branchType.name}
                                </option>
                            ))}
                            </select>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaMapMarked className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Estado</label>
                        </div>
                        <select
                            id="state"
                            value={selectedState}
                            onChange={handleStateChange}
                            className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                            required
                            >
                            <option value="">Selecione um estado</option>
                            {Object.keys(localities).map((state) => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                            </select>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex items-center space-x-2">
                          <FaMapMarked className="text-gray-500" />
                          <label className="text-gray-700 font-medium">Cidade</label>
                        </div>
                        <select
                                id="city"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                                className="w-full bg-white-200 text-gray-700 border border-gray-300 rounded-md py-2 px-4"
                                disabled={!selectedState}
                                required
                                >
                                <option value="">Selecione uma cidade</option>
                                {selectedState && localities[selectedState]?.map((city) => (
                                    <option key={city} value={city}>{city}</option>
                                ))}
                                </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-8 flex justify-start">
                <button
                  type="submit"
                  className={`bg-mosaico text-white px-4 py-2 rounded-md ${loadingButton ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={loadingButton}
                >
                  {loadingButton ? 'Salvando...' : 'Salvar'}
                </button>
              </div>
            </div>
          ) : (
            <p className="text-gray-600">Nenhum usuário encontrado.</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminUserEditPage;
