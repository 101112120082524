import axios from 'axios';
import { api } from './axios';

export const fetchCupoms = async () => {
    try {
        const response = await api.get('cupoms');
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return {
            status: 500,
            message: 'Error fetching categories',
            data: { cupoms: [] },
        };
    }
};


export const createCupom = async (
    company_id: string, 
    description: string,
    due_day: string,
    category_id: string,
    caption: File,
    localities: number[]
) => {
    try {
        const formData = new FormData();
        formData.append('company_id', company_id.toString());
        formData.append('due_day', due_day); // Use toISOString() para formato de data correto
        formData.append('description', description);
        formData.append('category_id', category_id.toString());
        formData.append('caption', caption);

        localities.forEach(locality => {
            formData.append('localities[]', locality.toString());
        });

        const response = await api.post('cupoms', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            },
        });

        return {
            success: true, // Adiciona uma propriedade 'success' para indicar sucesso
            status: response.status,
            message: 'Cupom created successfully', // Corrigido para "Cupom"
            data: response.data,
        };

    } catch (error) {
        let errorMessage = 'An error occurred during cupom registration.'; // Mensagem padrão

        if (axios.isAxiosError(error) && error.response) {
            errorMessage = error.response.data?.message || errorMessage; // Mensagem de erro da resposta
            console.error('Error during cupom registration:', error.response.data);
        } else {
            console.error('Error during cupom registration:', error);
        }

        return {
            success: false, // Adiciona uma propriedade 'success' para indicar falha
            message: errorMessage,
            error: error, // Opcional: retorna o objeto de erro completo
        };
    }
};

export const createPlan = async (name: string, caption: File, visible: boolean) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('caption', caption);
        formData.append('visible', visible.toString()); // Convertendo boolean para string

        // Fazendo a requisição POST para criar a categoria
        const response = await api.post('categories', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
               'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            },
        });

        return {
            status: response.status,
            message: 'Category created successfully',
            data: response.data,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during company registration:', error.response.data);
        } else {
            console.error('Error during company registration:', error);
        }
        throw error;
    }
};