import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import RedirectIfLoggedIn from '../components/RedirectToLogin';
import { login } from '../services/authService';


const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState('user');

  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await login(email, password, role);
      if (response.status === 200 && response.data.token) {
        sessionStorage.setItem('authToken', response.data.token);
        sessionStorage.setItem('role', role );
        toast.success('Login realizado com sucesso!');

        if(role === 'user'){
          navigate('/');
        }else if(role === 'admin'){
          navigate('/admin');
        }else if(role === 'company'){
          navigate('/companies');
        }else{
          navigate('/login');
        }
        
      } else {
        toast.error(response.message || 'Ocorreu um erro durante o login.');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data;

        if (errorResponse && typeof errorResponse === 'object') {
          const errorMessage = errorResponse.message || 'Ocorreu um erro durante o login.';
          toast.error(errorMessage);
        } else {
          toast.error('Ocorreu um erro durante o login.');
        }
      } else {
        toast.error('Ocorreu um erro durante o login.');
      }
      console.error('Error during login:', error);
    } finally {
      setLoading(false);
    }
  };

  const navigateRegister = () => {
    navigate('/register');
  };

  return (
    <div className="flex min-h-screen">
      <RedirectIfLoggedIn /> 
      <div className="hidden md:flex items-center justify-center w-1/2 bg-mosaico">
        <img src='./mosaico_teste.png' alt="Mosaico Background" className='w-1/2 h-auto' />
      </div>
      <div className="flex items-center justify-center w-full md:w-1/2 bg-white">
        <div className="p-6 rounded-lg w-full max-w-sm">
          <div className="flex justify-center mb-4">
            <img src="./logo.png" alt="Logo" className="h-20 w-auto" />
          </div>
          <h2 className="text-2xl font-texta-heavy">Bem Vindo(a) ao MosaicoPlus</h2>
          <h2 className="text-xs font-texta-regular mb-4">Entre na sua conta, e obtenha acesso aos recursos do nosso clube de benefícios.</h2>
          
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-texta-regular text-gray-700">
                Email
              </label>
              <div className="relative">
                <input
                  id="email"
                  type="email"
                  placeholder='email@example.com'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                  required
                />
                <FaEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-texta-regular text-gray-700">
                Senha
              </label>
              <div className="relative">
                <input
                  id="password"
                  type="password"
                  placeholder='Sua Senha'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                  required
                />
                <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            
            <div className="mb-4">
              <label className="block font-texta-regular text-gray-700">Entrar Como</label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="user"
                    checked={role === 'user'}
                    onChange={() => setRole('user')}
                    className="font-texta-regular form-radio text-mosaico checked:bg-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Usuário</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="company"
                    checked={role === 'company'}
                    onChange={() => setRole('company')}
                    className="font-texta-regular form-radio text-mosaico checked:bg-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Parceiro</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="role"
                    value="admin"
                    checked={role === 'admin'}
                    onChange={() => setRole('admin')}
                    className="font-texta-regular form-radio text-mosaico checked:bg-mosaico focus:ring-mosaico"
                  />
                  <span className="ml-2 font-texta-regular text-sm">Administrador</span>
                </label>
              </div>
            </div>
            <div className="mb-4 flex justify-between items-center">
              <Link to="/forgot-password" className="text-sm font-texta-regular text-mosaico hover:underline">
                Esqueci minha senha
              </Link>
            </div>
            
            <button
              type="submit"
              className="font-texta-heavy w-full bg-mosaico border border-mosaico text-white p-2 rounded-md shadow-sm hover:bg-white hover:text-mosaico hover:border-mosaico"
              disabled={loading}
            >
              {loading ? 'Carregando...' : 'Entrar'}
            </button>
            <div className="flex items-center justify-center mt-2">
                <span className="text-black font-texta-regular">
                   Ainda não tem cadastro?{' '}
                </span>
                <button
                    onClick={navigateRegister}
                    className="text-mosaico hover:underline font-texta-bold ml-1"
                >Faça uma conta
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
