import React, { useState, useEffect } from 'react';
import { FaBookOpen, FaUpload, FaEye, FaMapMarkedAlt, FaCalendarAlt, FaPen } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { createCategory, fetchCategories } from '../../services/categoryService';
import { getCurrentUser } from '../../services/authService';
import { Category } from '../../interfaces/category_interface';
import { Company, Locality2 } from '../../interfaces/company_interface';
import { fetchCompanies } from '../../services/companyService';
import { fetchLocalities2 } from '../../services/localityService'; // Importação da função para buscar localidades
import Select from 'react-select'; // Importando o react-select
import { createCupom } from '../../services/cupom_services';

const CupomsCreatePage: React.FC = () => {
    const [caption, setCaption] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean | null>(null);
    const [userRole, setUserRole] = useState<string | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');

    const [companies, setCompanies] = useState<Company[]>([]);
    const [localities, setLocalities] = useState<Locality2[]>([]); 
    const [selectedLocalities, setSelectedLocalities] = useState<any[]>([]); 
    const [dueDate, setDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [selectedLocalityIds, setSelectedLocalityIds] = useState<number[]>([]);


    const navigate = useNavigate();

    const fetchCompaniesData = async () => {
        try {
            const response = await fetchCompanies();
            if (response.status === 200) {
                setCompanies(response.data || []);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('Failed to fetch companies.');
        }
    };

    const handleDueDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedDate = e.target.value;
        const today = new Date().toISOString().split('T')[0]; 

        if (selectedDate <= today) {
            toast.error('A data de vencimento não pode ser menor ou igual à data de hoje.');
            setDueDate('');
        } else {
            setDueDate(selectedDate);
        }
    };



    useEffect(() => {
        const fetchCategoriesData = async () => {
            try {
                const response = await fetchCategories();
                if (response.status === 200) {
                    setCategories(response.data.categories || []);
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                toast.error('Failed to fetch categories.');
            }
        };

        fetchCategoriesData();
    }, []);

    // Requisição para buscar as localidades
    useEffect(() => {
        const fetchLocalitiesData = async () => {
            try {
                const localitiesData = await fetchLocalities2();
                setLocalities(localitiesData);
            } catch (error) {
                toast.error('Failed to fetch localities.');
            }
        };

        fetchLocalitiesData();
    }, []);

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const user = await getCurrentUser();
                if (user && (user.role === 'admin' || user.role === 'company')) {
                    setUserRole(user.role);
                    setHasAccess(true);
                    fetchCompaniesData();
                } else {
                    setHasAccess(false);
                    navigate('/unauthorized');
                }
            } catch (error) {
                setHasAccess(false);
                navigate('/unauthorized');
            }
        };

        checkAccess();
    }, [navigate]);

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
    
        try {
            if (!caption) {
                toast.error('Please upload an image for the cupom.');
                setLoading(false);
                return;
            }
    
            // Chame a função createCupom
            const response = await createCupom(selectedCompany, description, dueDate, selectedCategory, caption, selectedLocalityIds);
    
            // Verifique o retorno para exibir a mensagem correta
            if (response.success) {
                toast.success(response.message);
                navigate('/cupoms');
            } else {
                toast.error(response.message); // Mostra a mensagem de erro retornada
            }
        } catch (error) {
            // Caso a função createCupom lance um erro não capturado
            toast.error('Failed to create cupom.'); // Mensagem genérica de erro
            console.error('Error during cupom registration:', error); // Log do erro para depuração
        } finally {
            setLoading(false);
        }
    };
    

    const handleLocalityChange = (selectedOptions: any) => {
        // Atualiza o estado com as localidades selecionadas
        setSelectedLocalities(selectedOptions || []);
    
        // Obtém os IDs das localidades selecionadas
        const selectedIds: number[] = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
        
        // Mostra os IDs no console
        console.log("Selected IDs: ", selectedIds);

        setSelectedLocalityIds(selectedIds);


        console.log(selectedLocalityIds);
    };

    if (hasAccess === null) {
        return <div>Loading...</div>;
    }

    const options = localities.map((state) => ({
        label: state.name,
        options: state.children.map((city: Locality2) => ({
            label: city.name,
            value: city.id, 
        })),
    }));

    return (
        <div className="flex h-screen bg-gray-100 font-texta-regular">
            <Sidebar />
            <div className="flex-grow p-8 flex-1 overflow-y-auto">
                <h2 className="text-3xl font-texta-heavy text-mosaico mb-8">Novo Cupom</h2>

                <form onSubmit={handleRegister}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaBookOpen className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Categoria</span>
                            </label>
                            <select
                                id="category"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                                required
                            >
                                <option value="">Selecione uma categoria</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {userRole === 'admin' && (
                            <div className="flex flex-col">
                                <label className="flex items-center space-x-2 mb-2">
                                    <FaBookOpen className="text-mosaico" />
                                    <span className="text-gray-700 font-medium">Empresa</span>
                                </label>
                                <select
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}

                                    id="company"
                                    className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                                >
                                    <option value="">Selecione uma empresa</option>
                                    {companies.map((company) => (
                                        <option key={company.id} value={company.id}>
                                            {company.company_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaMapMarkedAlt className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Localidades</span>
                            </label>
                            <Select
                                isMulti
                                options={options} // Usando a estrutura de estados e cidades
                                onChange={handleLocalityChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Selecione as localidades..."
                                value={selectedLocalities} // Valor atual selecionado
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaUpload className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Imagem (Caption)</span>
                            </label>
                            <input
                                type="file"
                                required
                                onChange={(e) => setCaption(e.target.files ? e.target.files[0] : null)}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaCalendarAlt className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Data de Vencimento</span>
                            </label>
                            <input
                                type="date"
                                value={dueDate}
                                onChange={handleDueDateChange}
                                className="w-full bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                                required
                            />
                        </div>
                    </div>
                    <div className="flex flex-col mt-4">
                            <label className="flex items-center space-x-2 mb-2">
                                <FaPen className="text-mosaico" />
                                <span className="text-gray-700 font-medium">Descrição</span>
                            </label>
                            <textarea
                                rows={4}

                                value={description}
                                onChange={(e) => setDescription(e.target.value)} // Ajuste para armazenar o valor no estado
                                className="w-full resize-none bg-white text-gray-700 border border-gray-300 rounded-md py-2 px-4 focus:border-mosaico focus:ring-2 focus:ring-mosaico"
                                required
                            />
                        </div>

                    <div className="flex justify-end mt-6">
                        <button
                            type="submit"
                            className="bg-mosaico text-white font-bold py-2 px-4 rounded shadow hover:bg-opacity-90"
                            disabled={loading}
                        >
                            {loading ? 'Criando...' : 'Criar Cupom'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CupomsCreatePage;
