import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar';
import { FaSearch, FaUsers, FaBuilding, FaTags, FaDollarSign, FaChevronDown } from 'react-icons/fa';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement
} from 'chart.js';
import { fetchDataAdminDash } from '../../services/adminService';

// Register ChartJS components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement);

const AdminPage: React.FC = () => {
  const isLoggedIn = !!sessionStorage.getItem('authToken');
  const role = sessionStorage.getItem('role');
  const [search, setSearch] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userCount, setUserCount] = useState<number | null>(null);
  const [companyCount, setCompanyCount] = useState<number | null>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchDataAdminDash();
        setUserCount(data.users);
        setCompanyCount(data.companies);
      } catch (error) {
        console.error('Error fetching admin data:', error);
      }
    };

    if (isLoggedIn && role === 'admin') {
      fetchData();
    }
  }, [isLoggedIn, role]);

 

   // Sample data for charts
   const revenueData = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
      {
        label: 'Faturamento Mensal',
        data: [1200, 1900, 3000, 5000, 4000, 2500, 3000, 3200, 13577.35, 4200, 4500, 5000],
        borderColor: '#E51E25',
        backgroundColor: 'rgba(229, 30, 37, 0.2)', 
        tension: 0.8, 
        pointRadius: 4, 
        pointBackgroundColor: '#E51E25', 
        pointBorderColor: '#fff', 
        pointBorderWidth: 2, 
      },
    ],
  };

  const offersByCategoryData = {
    labels: ['Categoria 1', 'Categoria 2', 'Categoria 3', 'Categoria 4', 'Categoria 5'],
    datasets: [
      {
        label: 'Ofertas por Categoria',
        data: [50, 60, 70, 80, 90],
        backgroundColor: ['#F87171', '#FBBF24', '#34D399', '#60A5FA', '#9333EA'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem: any) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
      y: {
        grid: {
          display: false, // Hide y-axis grid lines
        },
        ticks: {
          font: {
            size: 12,
          },
          callback: function(value: number) {
            return `${value}`; // Show raw numbers
          },
        },
      },
    },
  };

  return (
    <div className="flex h-screen">
      <Sidebar /> {/* Sidebar à esquerda */}
      
      <div className="flex-grow flex flex-col">
        {/* Navbar */}
        <nav className="bg-gray-50 shadow-md px-6 py-4 flex justify-between items-center w-100">
          <div className="flex items-center space-x-2 w-full">
            <div className="relative flex-grow">
              <input
                id="search"
                type="text"
                placeholder="Busque aqui..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="font-texta-regular text-sm mt-1 p-2 w-full border border-gray-300 rounded-md shadow-sm bg-gray-200 pl-10"
                required
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>

          <div className="ml-4 relative">
            <div onClick={toggleDropdown} className="cursor-pointer flex items-center space-x-2">
              <img
                src="https://i.pravatar.cc/150?img=3" 
                alt="User Avatar"
                className="w-10 h-10 rounded-full border-2 border-mosaic-500"
              />
              <FaChevronDown className="text-gray-600" />
            </div>

            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg py-1 z-10">
                <a href="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Profile</a>
                <a href="/settings" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Settings</a>
                <a href="/logout" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">Logout</a>
              </div>
            )}
          </div>
        </nav>
        
        {/* Main Content */}
        <div className="flex flex-col flex-grow p-6 bg-gray-50">
          <h2 className="text-2xl font-texta-regular text-gray-700 mb-6">Dashboard</h2>
          
          {/* Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white shadow rounded-lg p-6 flex items-center">
              <div className="text-mosaic-600 p-3 bg-mosaic-100 rounded-full">
                <FaUsers className="text-3xl" />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-texta-book text-gray-700">Clientes</h3>
                <p className="text-5xl font-texta-black text-mosaico">{userCount !== null ? userCount : '...'}</p>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6 flex items-center">
              <div className="text-mosaic-600 p-3 bg-mosaic-100 rounded-full">
                <FaBuilding className="text-3xl" />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-texta-book text-gray-700">Parceiros</h3>
                <p className="text-5xl font-texta-black text-mosaico">{companyCount !== null ? companyCount : '...'}</p>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6 flex items-center">
              <div className="text-mosaic-600 p-3 bg-mosaic-100 rounded-full">
                <FaTags className="text-3xl" />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-texta-book text-gray-700">Cupons/Ofertas</h3>
                <p className="text-5xl font-texta-black text-mosaico">15.000</p>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6 flex items-center">
              <div className="text-mosaic-600 p-3 bg-mosaic-100 rounded-full">
                <FaDollarSign className="text-3xl" />
              </div>
              <div className="ml-4">
                <h3 className="text-xl font-texta-book text-gray-700">Ganhos Mensais</h3>
                <p className="text-5xl font-texta-black text-mosaico">13.577,35</p>
              </div>
            </div>
          </div>
          
          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-4">
            {/* Revenue Chart */}
            <div className="bg-white shadow rounded-lg p-6 h-80">
              <h3 className="text-xl font-texta-book text-gray-700 mb-4">Faturamento Anual</h3>
              <Line data={revenueData} options={chartOptions as any} />
            </div>

            {/* Offers by Category Chart */}
            <div className="bg-white shadow rounded-lg p-6 h-80">
              <h3 className="text-xl font-texta-book text-gray-700 mb-4">Ofertas por Categoria</h3>
              <Bar data={offersByCategoryData} options={chartOptions as any} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
