
import axios from 'axios';
import { Company } from '../interfaces/company_interface';
import { api } from './axios';


export const registerCompany = async (
    company_name: string, 
    address: string,
    phone: string,
    city_name: string,
    state_name: string,
    branch_type_id: string,
    state_registration: string,
    cnpj: string,
    userId?: number
) => {
    const requestData: { [key: string]: any } = {
        company_name,
        address,
        phone,
        city_name,
        state_name,
        branch_type_id,
        state_registration,
        cnpj
    };

    if (userId) {
        requestData.user_id = userId;
    }

    try {
        const response = await api.post('companies', requestData, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during company registration:', error.response.data);
        } else {
            console.error('Error during company registration:', error);
        }
        throw error;
    }
};

export const fetchEmpresaDetail = async (companyId: string) => {
    try {
        const response = await api.get(`companies/${companyId}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during fetch company:', error.response.data);
        } else {
            console.error('Error during fetch company:', error);
        }
        throw error;
    }
};



export const fetchCompanies = async () => {
    try {
        const response = await api.get(`companies`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return {
            status: 500,
            message: 'Error fetching categories',
            data: { categories: [] },
        };
    }
};

export const updateCompanyByAdmin = async (company: Company, state: string, city: string) => {
    const requestData = {
        company_name: company.company_name,
        cnpj: company.cnpj,
        address: company.address,
        phone: company.phone,
        state_registration: company.state_registration,
        branch_type_id: company.branch_type_id,
        state_name: state,
        city_name: city
    };

    try {
        const response = await api.put(`companies/${company.id}`, requestData, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during update company:', error.response.data);
        } else {
            console.error('Error during update company:', error);
        }
        throw error;
    }
};
