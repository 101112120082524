import React from 'react';
import RedirectIfLoggedIn from '../../components/RedirectToLogin';

const HomePageCompanyPage: React.FC = () => {
  return (
    <div className="p-4">
      <RedirectIfLoggedIn /> 
      <h2 className="text-xl font-bold">About Page</h2>
      <p>This is the about page.</p>
    </div>
  );
};

export default HomePageCompanyPage;
