import axios from 'axios';
import { api } from './axios';



export const fecthPlans = async () => {
    try {
        const response = await api.get('plans', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during fetch admin dashboard data:', error.response.data);
        } else {
            console.error('Error during fetch admin dashboard data:', error);
        }
        throw error;
    }
};

export const deletePlan = async (idPlan: number) => {
    try {
        const response = await api.delete(`plans/${idPlan}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error deleting plan:', error.response.data);
            return {
                status: error.response.status,
                message: error.response.data.message || 'Error deleting plan',
            };
        } else {
            console.error('Error deleting plan:', error);
            return {
                status: 500,
                message: 'Error deleting plan',
            };
        }
    }
};

export const createPlan = async (
    name: string,
    visible:boolean,
    price: number,
    description: string,
    payment_billing: string,
    role: string
) => {
    const requestData = {
        name : name,
        visible: visible,
        price: price,
        role: role,
        description: description,
        payment_billing: payment_billing,
    };

    console.log("REQES");
    console.log(requestData);


    try {
        const response = await api.post('plans', requestData, { headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
        }}, );
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during registration:', error.response.data);
        } else {
            console.error('Error during registration:', error);
        }
        throw error;
    }
};