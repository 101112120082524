import axios from 'axios';
import { api } from './axios';



export const fetchCategories = async () => {
    try {
        const response = await api.get('categories');
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        return {
            status: 500,
            message: 'Error fetching categories',
            data: { categories: [] },
        };
    }
};


export const deleteCategory = async (idCategory: number) => {
    try {
        const response = await api.delete(`categories/${idCategory}`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error deleting category:', error.response.data);
            return {
                status: error.response.status,
                message: error.response.data.message || 'Error deleting category',
            };
        } else {
            console.error('Error deleting category:', error);
            return {
                status: 500,
                message: 'Error deleting category',
            };
        }
    }
};

export const createCategory = async (name: string, caption: File, visible: boolean) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('caption', caption);
        formData.append('visible', visible.toString()); // Convertendo boolean para string

        // Fazendo a requisição POST para criar a categoria
        const response = await api.post('categories', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
               'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            },
        });

        return {
            status: response.status,
            message: 'Category created successfully',
            data: response.data,
        };
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error('Error during company registration:', error.response.data);
        } else {
            console.error('Error during company registration:', error);
        }
        throw error;
    }
};


export const updateVisibility = async (id: number, visible:boolean) =>{
    try {
        const response = await api.put(`categories/${id}`, { visible }, {
            headers: {
                 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            console.error(error.response?.data?.message || 'Failed to update visibility.');
        } else {
            console.error(error || 'Failed to update visibility.');
        }
        throw error;
    }
}