import React, { useEffect, useState } from 'react';
import { FaHome, FaUser, FaCog, FaBookOpen, FaMoneyBill } from 'react-icons/fa';

const Sidebar: React.FC = () => {
  const [role, setRole] = useState<string | null>(null);

  useEffect(() => {
    const storedRole = sessionStorage.getItem('role');
    setRole(storedRole);
  }, []);

  const menuItems = [
    { name: 'Dashboard', icon: <FaHome />, route: '/' },
    { name: 'Clientes', icon: <FaUser />, route: '/admin/users', roles: ['admin'] },
    { name: 'Categorias', icon: <FaBookOpen />, route: '/admin/categories', roles: ['admin',] },
    { name: 'Planos', icon: <FaBookOpen />, route: '/admin/planos', roles: ['admin',] },
    { name: 'Cupons', icon: <FaMoneyBill />, route: '/cupoms', roles: ['admin', 'company'] },
    { name: 'Settings', icon: <FaCog />, route: '/settings', roles: ['admin', 'user', 'company'] },
  ];

  return (
    <div className="flex flex-col h-screen w-64 bg-mosaico text-white text-texta-regular text-sm">
      <div className="flex items-center justify-center h-16">
        <img src={`${process.env.PUBLIC_URL}/header.png`} alt="Logo" className="h-12 p-2" />
      </div>
      <nav className="flex-1 px-4 py-8 overflow-y-auto">
        <ul className="space-y-4">
          {role &&
            menuItems.map(
              (item) =>
                (!item.roles || item.roles.includes(role)) && (
                  <li key={item.name} className="text-texta-regular">
                    <a
                      href={item.route}
                      className="flex items-center space-x-4 p-2 rounded-md hover:bg-mosaic-600 transition-colors"
                    >
                      <span className="text-xl">{item.icon}</span>
                      <span>{item.name}</span>
                    </a>
                  </li>
                )
            )}
        </ul>
      </nav>
      <div className="px-4 py-4 border-t border-mosaic-600">
        <button className="w-full text-left flex items-center space-x-4 text-texta-regular hover:text-mosaic-200">
          <FaCog className="text-xl" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
